// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-ford-focus-js": () => import("./../../../src/pages/ford-focus.js" /* webpackChunkName: "component---src-pages-ford-focus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poptat-js": () => import("./../../../src/pages/poptat.js" /* webpackChunkName: "component---src-pages-poptat-js" */),
  "component---src-pages-skoda-fabia-iii-style-tdi-js": () => import("./../../../src/pages/skoda-fabia-iii-style-tdi.js" /* webpackChunkName: "component---src-pages-skoda-fabia-iii-style-tdi-js" */),
  "component---src-pages-skoda-fabia-js": () => import("./../../../src/pages/skoda-fabia.js" /* webpackChunkName: "component---src-pages-skoda-fabia-js" */),
  "component---src-pages-skoda-octavia-ii-facelift-16-tdi-combi-js": () => import("./../../../src/pages/skoda-octavia-ii-facelift-16tdi-combi.js" /* webpackChunkName: "component---src-pages-skoda-octavia-ii-facelift-16-tdi-combi-js" */),
  "component---src-pages-skoda-octavia-iii-16-tdi-combi-js": () => import("./../../../src/pages/skoda-octavia-iii-16tdi-combi.js" /* webpackChunkName: "component---src-pages-skoda-octavia-iii-16-tdi-combi-js" */),
  "component---src-pages-skoda-octavia-iii-tdi-dsg-automovat-js": () => import("./../../../src/pages/skoda-octavia-iii-tdi-dsg-automovat.js" /* webpackChunkName: "component---src-pages-skoda-octavia-iii-tdi-dsg-automovat-js" */),
  "component---src-pages-skoda-superb-ii-16-tdi-limuzina-js": () => import("./../../../src/pages/skoda-superb-ii-16tdi-limuzina.js" /* webpackChunkName: "component---src-pages-skoda-superb-ii-16-tdi-limuzina-js" */),
  "component---src-pages-skoda-superb-iii-tdi-limuzina-cerna-js": () => import("./../../../src/pages/skoda-superb-iii-tdi-limuzina-cerna.js" /* webpackChunkName: "component---src-pages-skoda-superb-iii-tdi-limuzina-cerna-js" */),
  "component---src-pages-skoda-superb-iii-tdi-limuzina-js": () => import("./../../../src/pages/skoda-superb-iii-tdi-limuzina.js" /* webpackChunkName: "component---src-pages-skoda-superb-iii-tdi-limuzina-js" */),
  "component---src-pages-vzor-pre-detail-js": () => import("./../../../src/pages/vzor-pre-detail.js" /* webpackChunkName: "component---src-pages-vzor-pre-detail-js" */)
}

